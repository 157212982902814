import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Input, Modal, Select, Switch, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const { Search } = Input;
const { Option } = Select;

const UserPage = ({ userRole }) => {
  const [admins, setAdmins] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [events, setEvents] = useState([]);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [newAdminPassword, setNewAdminPassword] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch admins and events
  useEffect(() => {
    const fetchAdminsAndEvents = async () => {
      try {
        const adminsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/admins`, { withCredentials: true });
        const eventsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/events`, { withCredentials: true });
        
        setAdmins(adminsResponse.data);
        setFilteredAdmins(adminsResponse.data);
        setEvents(eventsResponse.data);
      } catch (error) {
        toast.error('Error fetching admins or events');
      }
    };

    fetchAdminsAndEvents();
  }, []);

  // Handle search
  const handleSearch = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    setSearchTerm(lowercasedValue);
  
    const filtered = admins.filter(admin =>
      admin.email.toLowerCase().includes(lowercasedValue)
    );
    setFilteredAdmins(filtered);
  };

  // Handle add admin
  const handleAddAdmin = async () => {
    if (!newAdminEmail || !newAdminPassword || selectedEvents.length === 0) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/admins`, {
        email: newAdminEmail,
        password: newAdminPassword,
        allowedEvents: selectedEvents,
      }, { withCredentials: true });
      toast.success('Admin added successfully');
      setShowAddAdminModal(false);
      setNewAdminEmail('');
      setNewAdminPassword('');
      setSelectedEvents([]);
    } catch (error) {
      toast.error('Error adding admin');
    }
  };

  // Handle activate/deactivate admin
  const toggleUserStatus = async (userId) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/admins/${userId}/toggle-status`, {}, { withCredentials: true });
      const updatedAdmins = admins.map(admin => 
        admin._id === userId ? { ...admin, active: response.data.active } : admin
      );
      setAdmins(updatedAdmins);
      setFilteredAdmins(updatedAdmins);
      toast.success('User status updated');
    } catch (error) {
      toast.error('Error updating user status');
    }
  };

  // Table columns
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Allowed Events',
      dataIndex: 'allowedEvents',
      key: 'allowedEvents',
      render: (allowedEvents) => allowedEvents.map(event => event.name).join(', '),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active, record) => (
        userRole === 'admin' && (
          <Switch
            checked={active}
            onChange={() => toggleUserStatus(record._id)}
          />
        )
      ),
    },
  ];

  return (
    <div className="user-page">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Search
            placeholder="Search admins by email"
            onChange={handleSearch} // Use onChange instead of onSearch
            value={searchTerm}
            enterButton
        />
        {userRole === 'admin' && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddAdminModal(true)}
          >
            Add Admin
          </Button>
        )}
      </div>

      <Table
        columns={columns}
        dataSource={filteredAdmins}
        rowKey="_id"
      />

      <Modal
        title="Add Admin"
        open={showAddAdminModal}
        onCancel={() => setShowAddAdminModal(false)}
        onOk={handleAddAdmin}
      >
        <Input
          placeholder="Admin Email"
          value={newAdminEmail}
          onChange={(e) => setNewAdminEmail(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Input.Password
          placeholder="Password"
          value={newAdminPassword}
          onChange={(e) => setNewAdminPassword(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <Select
          mode="multiple"
          placeholder="Select Events"
          style={{ width: '100%' }}
          value={selectedEvents}
          onChange={setSelectedEvents}
        >
          {events.map((event) => (
            <Option key={event._id} value={event._id}>
              {event.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default UserPage;
