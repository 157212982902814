import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Moved useNavigate to LoginForm component

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email,
        password,
      }, { withCredentials: true });
      
      onLogin(res.data.role, email, res.data.allowedEvents);

      // Redirect based on role
      if (res.data.role === 'admin') {
        navigate('/event-dashboard'); // Redirect admin to the dashboard
      } else if (res.data.allowedEvents && res.data.allowedEvents.length > 0) {
        navigate(`/events/${res.data.allowedEvents[0]._id}`); // Redirect to first allowed event for non-admin users
      } else {
        navigate('/'); // Default fallback if no allowed events
      }
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <form onSubmit={handleLogin}>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="mb-3 input-group">
        <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} /></span>
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="mb-3 input-group">
        <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">Login</button>
    </form>
  );
};

export default LoginForm;
