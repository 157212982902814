import React from 'react';
import '../styles/navbar.css'; // Importing custom styles

const Navbar = ({ username }) => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        {username ? <h4>Welcome, {username}</h4> : <h4>Loading...</h4>} {/* Conditional rendering */}
      </div>
    </nav>
  );
};

export default Navbar;
