import React from 'react';
import LoginForm from '../../components/LoginForm';
import '../../styles/login.css'; // Custom CSS for the page

const Login = ({ onLogin }) => {
  return (
    <div className="login-page d-flex align-items-center justify-content-center">
      <div className="login-container">
        <div className="card glass-card shadow-lg animate__animated animate__fadeIn">
          <div className="card-body p-5">
            <LoginForm onLogin={onLogin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
