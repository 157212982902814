// services/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ isAuthenticated, userRole, requiredRoles = [], children }) => {
  if (!isAuthenticated) {
    toast.error('You must be logged in to access this page.');
    return <Navigate to="/" />;
  }

  if (requiredRoles.length && !requiredRoles.includes(userRole)) {
    toast.error('You do not have permission to access this page.');
    return <Navigate to="/event-dashboard" />; // Redirect to a specific page like the dashboard
  }

  return React.cloneElement(children, { userRole });
};

export default ProtectedRoute;
