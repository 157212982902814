import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Typography } from 'antd';
import axios from 'axios';

const { Title, Text } = Typography;

const ResponsePage = () => {
  const [searchParams] = useSearchParams();
  const [responseStatus, setResponseStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [qrCodePath, setQrCodePath] = useState(null);  // Add state for QR code path

  const customerId = searchParams.get('customerId');
  const response = searchParams.get('response');

  useEffect(() => {
    let isMounted = true;

    const submitResponse = async () => {
      if (isMounted && customerId && response) {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/invite/response`, {
            params: { customerId, response },
          });

          setResponseStatus(data.status);
          if (data.status === 'accepted' && data.qrCodePath) {
            setQrCodePath(data.qrCodePath);  // Set the QR code path from the response
          }
        } catch (error) {
          if (error.response?.status === 409) {
            setErrorMessage('You have already responded to this invitation.');
          } else {
            setErrorMessage(error.response?.data?.message || 'Error processing your response');
          }
        }
      }
    };

    submitResponse();

    return () => {
      isMounted = false; // Cleanup to prevent the effect from being executed again
    };
  }, [customerId, response]);

  if (errorMessage) {
    return (
      <Card style={{ textAlign: 'center', maxWidth: 600, margin: 'auto', marginTop: '100px', padding: '30px' }}>
        <Title level={3}>{errorMessage === 'You have already responded to this invitation.' ? 'Sorry' : 'Invitation Expired'}</Title>
        <Text>{errorMessage}</Text>
      </Card>
    );
  }

  return (
    <Card style={{ textAlign: 'center', maxWidth: 600, margin: 'auto', marginTop: '100px', padding: '30px' }}>
      {responseStatus === 'accepted' ? (
        <>
          <Title level={2}>THANK YOU FOR YOUR REGISTRATION</Title>
          
          {qrCodePath && (
            <>
              <br />
              <img
                src={`${process.env.REACT_APP_STORAGE}/${qrCodePath}`}  // Display the QR code image
                alt="QR Code"
                style={{ width: '150px', height: '150px', marginTop: '20px' }}
              />
              <br />
            </>
          )}

          <br />
          <Text>Your QR code is above, please kindly note that your QR code is required for check-in.</Text>
          <br />
          <Text>Hafele Thailand 30TH Anniversary</Text>
          <Text>The Gala Night Celebrating 30 Years of Maximising The Value of Space. Together</Text>
          <Text>@Plenary Halls 1-2, 1st Floor</Text>
          <Text>Queen Sirikit National Convention Center</Text>
          <br />
          <br />
          <Text>We look forward to welcoming you.</Text>
          <br />
          <br />
          <Text>Sincerely,</Text>
          <Text>Hafele Thailand</Text>
        </>
      ) : responseStatus === 'declined' ? (
        <>
          <Title level={2}>Sorry to See You Go</Title>
          <Text>You've successfully declined the invitation. We hope to see you at future events.</Text>
        </>
      ) : responseStatus === 'expired' ? (
        <>
          <Title level={2}>Invitation Expired</Title>
          <Text>Unfortunately, the time to respond has passed. We hope to invite you again in the future.</Text>
        </>
      ) : (
        <Text>Processing your response...</Text>
      )}
    </Card>
  );
};

export default ResponsePage;
