import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import '../../styles/EventRegistration.css'; // Create a custom CSS file for creative design

const EventRegistration = () => {
  const { eventId } = useParams(); // Get eventId from the route
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/${eventId}/register`, { ...values, type: 'Walk-In' });
      notification.success({
        message: 'Registration Successful',
        description: 'Thank you for registering!',
      });
    } catch (error) {
      notification.error({
        message: 'Registration Failed',
        description: 'Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="registration-container">
      <h2>Event Registration</h2>
      <Form layout="vertical" onFinish={onFinish} className="registration-form">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>

        <Form.Item label="Company Name (Optional)" name="company">
          <Input placeholder="Enter your company name" />
        </Form.Item>

        <Form.Item label="Position (Optional)" name="position">
          <Input placeholder="Enter your position" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item label="Phone (Optional)" name="phone">
          <Input placeholder="Enter your phone number" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} className="submit-button">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EventRegistration;
