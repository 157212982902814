import React from 'react';
import { FaBars, FaUser, FaCalendarAlt, FaSignOutAlt } from 'react-icons/fa'; // Importing icons
import { useNavigate } from 'react-router-dom'; // For redirection
import axios from 'axios'; // To make API calls
import '../styles/sidebar.css'; // Import the correct sidebar styles

const Sidebar = ({ isOpen, toggleSidebar, onLogout, userRole }) => {
  const navigate = useNavigate();

  // Ensure the toggleSidebar function exists and is passed correctly
  if (typeof toggleSidebar !== 'function') {
    console.error('toggleSidebar is not a function or is undefined');
  }

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, { withCredentials: true });
      onLogout(); // Call onLogout callback to clear state in App.js
      navigate('/'); // Redirect to login page after successful logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <FaBars className="toggle-btn" onClick={toggleSidebar} />
      </div>
      <ul className="menu-list">
        {/* Only show the dashboard and user sections if the user is an admin */}
        {userRole === 'admin' && (
          <>
            <li>
              <a href="/event-dashboard">
                <FaCalendarAlt className="menu-icon" />
                {isOpen && <span>Event Dashboard</span>}
              </a>
            </li>
            <li>
              <a href="/users">
                <FaUser className="menu-icon" />
                {isOpen && <span>User</span>}
              </a>
            </li>
          </>
        )}
        
        {/* All users can see the logout button */}
        <li>
          <button onClick={handleLogout} className="menu-icon" style={{ background: 'none', border: 'none', color: 'white' }}>
            <FaSignOutAlt />
            {isOpen && <span>Logout</span>}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
