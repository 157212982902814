import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import EventDashboard from './pages/EventDashboard';
import EventPage from './pages/EventPage';
import ResponsePage from './pages/ResponsePage';
import UserPage from './pages/User';
import EventRegistration from './pages/EventRegistration';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import ProtectedRoute from './services/ProtectedRoute';
import axios from 'axios';
import './styles/global.css';

const App = () => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [allowedEvents, setAllowedEvents] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    setUsername('');
    setAllowedEvents([]);
  };

  const handleLogin = (role, username, allowedEvents) => {
    setUserRole(role);
    setUsername(username);
    setIsAuthenticated(true);
    setAllowedEvents(allowedEvents || []);
  };

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/auth-status`, { withCredentials: true });
        setUserRole(response.data.role);
        setUsername(response.data.username);
        setAllowedEvents(response.data.allowedEvents || []);
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          handleLogout();
          // toast.error('Your session has expired. Please log in again.');
        } else {
          // toast.error('Error fetching user role and authentication status.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAuthStatus();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Router>
      {isAuthenticated ? (
        <div className={`app-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} onLogout={handleLogout} /> */}
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} onLogout={handleLogout} userRole={userRole} />
          <Navbar username={username} />
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Navigate to="/event-dashboard" />} />
              <Route
                path="/event-dashboard"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} userRole={userRole} requiredRoles={['admin']}>
                    <EventDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:eventId"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} userRole={userRole} requiredRoles={['admin', 'staff','customer']}>
                    <EventPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} userRole={userRole} requiredRoles={['admin']}>
                    <UserPage userRole={userRole} />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/events/:eventId/register" element={<EventRegistration />} />
          <Route path="/invitation-response" element={<ResponsePage />} /> {/* New route for invitation response */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <ToastContainer />
    </Router>
  );
};

export default App;
